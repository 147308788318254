$(document).on("turbo:load", function () {
  var $form = $("form#listing-edit-form")

  if ($form.length > 0) {
    $(".listing-packing input[type='radio']").on("change", function () {
      $("#user_listing_listing_attributes_ready_to_hang").prop("disabled", $(this).val() == "rolled")
    })

    $(document).on("submit", "#simple-upload", function (e) {
      var title = $("#listing-title-input").val()
      if (title == "") {
        alert("Please enter a title before uploading.")
        e.preventDefault()
      } else {
        $("#simple-file-title").val(title)
      }
    })

    userListingForm.init()

    // Autosaving

    $(".size-fields").on("keyup", function (e) {
      if (
        $.grep($(".size-fields"), function (s) {
          return $(s).val() > 300
        }).length
      ) {
        $("#size-warning").show()
      } else {
        $("#size-warning").hide()
      }
    })

    $form.on("blur", ".token-input", function () {
      var value = $("input.token-field").val()
      autosave("tags", value)
    })

    $form.on("change", ".autosave-change", function (e) {
      var attribute = $(this).attr("name").replace(/]/g, "").split("[").slice(-1)[0]
      if ($(this).is(":checkbox")) {
        var value = $(this).is(":checked")
      } else {
        var value = e.target.value
      }
      autosave(attribute, value)
    })

    $form.on("blur", ".autosave", function (e) {
      var attribute = $(this).attr("name").replace(/]/g, "").split("[").slice(-1)[0]
      var value = e.target.value
      autosave(attribute, value)
    })

    function autosave(attribute, value) {
      var data = {}
      data[attribute] = value

      $.ajax({
        url: "/my/artworks/" + userListingForm.listingID + "/autosave",
        data: { listing: data },
        method: "PATCH",
      })
    }
  }
})

var userListingForm = {
  init: function () {
    this.$form = $("#listing-edit-form")
    this.listingID = this.$form.data("listing-id")
    this.minimumPrice = this.$form.data("min-price")
    this.nude_collection_id = parseInt(this.$form.data("nude-collection-id"))
    this.enhanced_artist = this.$form.data("enhanced-artist")

    // Initialize token field
    $(".token-field").tokenfield({ createTokensOnBlur: true })

    this.handleAgreement()
    this.handleMediumsAndSignatures()
    this.handleMaterials()
    this.handleSizes()
    this.handleShipment()
    this.handleTotal()
    this.handleSaveDraft()
    this.handlePublishLater()
    this.handleNudeSubject()
    this.handleExclusive()
    this.listenSubmission()
    this.handleCleanTitleValidation()
    this.validateCleanTitle()
  },

  handleAgreement: function () {
    $("#artwork-agreement-input").on("click", function () {
      var action = $(this).is(":checked") ? "hide" : "show"
      $("#agreement").collapse(action)
    })
  },

  checkHangableMaterial: function (material) {
    const materials = ["metal", "glass", "other"]
    const unhangableMaterials = materials.includes(material)
    const hang = $(".ready-to-hang").find("label")

    if (unhangableMaterials) {
      hang.removeClass("required").addClass("hidden")
    } else {
      hang.removeClass("hidden").addClass("required")
    }
  },

  handleMaterials: function () {
    window.addEventListener("user_listing_listing_attributes_material", (e) => {
      const value = e.detail?.toLowerCase()
      this.checkHangableMaterial(value)

      this.selectedMaterial = value
      this.toggleMaterials("canvas", $(".stretched-canvas").find("label"), () => {
        $("#user_listing_listing_attributes_stretched_canvas").attr("checked", false)
      })
      this.toggleMaterials("other", $(".new-artwork__section-container--surface"))
    })
  },

  checkPrintable: function (value) {
    var printableMediums = [
      "Digital",
      "LinocutPrint",
      "Screenprinting",
      "Etching",
      "Woodcut",
      "ReproductionPrint",
      "Engraving",
      "Stencil",
      "Lithograph",
      "Collagraph",
      "Monotype",
      "Giclée",
    ]
    return printableMediums.includes(value)
  },

  checkPhotography: function (value) {
    return value == "Photograph"
  },

  handleMediumsAndSignatures: function () {
    window.addEventListener(
      "user_listing_listing_attributes_medium_id",
      (e) => {
        const value = e.detail?.replace(/ /g, "")
        this.checkReproducable(value)
      },
      false,
    )
  },

  checkReproducable: function (sanitizedSelection) {
    const mediums = ["Ceramic", "Sculpture", "Glass", "Weaving", "Bronze", "Metal"]
    const printable = this.checkPrintable(sanitizedSelection)
    const photography = this.checkPhotography(sanitizedSelection)
    const reproducable = printable || photography
    const unhangableMediums = mediums.includes(sanitizedSelection)

    this.$form.removeClass("printable photography reproducable unhangable")
    this.$form[printable ? "addClass" : "removeClass"]("printable")
    this.$form[photography ? "addClass" : "removeClass"]("photography")
    this.$form[reproducable ? "addClass" : "removeClass"]("reproducable")
    this.$form[unhangableMediums ? "addClass" : "removeClass"]("unhangable-mediums")

    const $limited = $(".limited-edition").find("label")

    if (printable || photography) {
      $limited.removeClass("hidden").addClass("required")
    } else {
      $limited.removeClass("required").addClass("hidden")
    }

    const $ready = $(".ready-to-hang").find("label")
    const $frameless = $("#user_listing_listing_attributes_frame").get(0)
    if (unhangableMediums) {
      $ready.removeClass("required").addClass("hidden")
      $frameless.value = "na"
      $frameless.disabled = true
    } else {
      $ready.removeClass("hidden").addClass("required")
      $frameless.disabled = false
    }

    photography && this.$form.find("#user_listing_listing_attributes_ready_to_hang").prop("checked", false)
    this.toggleSignaturesList(printable)
  },

  handleSizes: function () {
    var _this = this
    var $el = _this.$form.find("#user_listing_listing_attributes_frame")

    _this.toggleSizes($el)

    $el.on("change", function (e) {
      _this.toggleSizes($el)
    })
  },

  handleShipment: function () {
    var _this = this
    $(".shipment-type").on("change", function () {
      if ($(this).val() == "rolled") {
        $("#user_listing_listing_attributes_ready_to_hang").prop("checked", false)
      }
      _this.calculateTotal()
    })
  },

  handleTotal: function () {
    var _this = this
    $(".get-total-value").on(
      "keyup",
      debounce(function () {
        _this.calculateTotal()
      }, 1000),
    )

    $("#user_listing_listing_attributes_frame").on("change", () => this.calculateTotal())
  },

  handleSaveDraft: function () {
    $("#listing-save-draft").on("click", function () {
      $("#listing-edit-form").data("save-draft", true)
    })
  },

  handlePublishLater: function () {
    var $target = $("#listing-publish-later")
    $target.on("click", function () {
      $target.removeClass("btn-default").addClass("btn-primary")
      $("#publish-now").removeClass("btn-primary").addClass("btn-default")
      $("#publish-later").removeClass("hidden")
      return false
    })
  },

  handleNudeSubject: function () {
    window.addEventListener("listing-subject-collection-changed", (e) => {
      if (e.detail.includes(this.nude_collection_id))
        document.getElementById("user_listing_listing_attributes_nudity_true").checked = true
    })
  },

  handleExclusive: function () {
    if (!this.enhanced_artist) return

    document.getElementById("user_listing_listing_attributes_exclusive").addEventListener("change", (e) => {
      this.manageDraftBtn(e.target.checked)
    })
  },

  manageDraftBtn(disable) {
    const draft_btn = document.getElementById("listing-save-draft")
    draft_btn && (draft_btn.disabled = disable)
  },

  handleUploadError: function ($uploader, $message) {
    $uploader.find(".primary-frame-error, .primary-error").addClass("hidden")
    $message.removeClass("hidden", function () {
      bt.scrollTo($(this))
    })
  },

  checkFrameable: function () {
    const frame_status = $(".listing_frame_status").val()
    const availability = $(".listing_availability").val()

    const status = ["false", "na", "by_request"]
    const materials = ["canvas", "paper", "wood", "cardboard", "linen"]

    return status.includes(frame_status) && materials.includes(this.selectedMaterial) && availability !== "sold"
  },

  listenSubmission: function () {
    var _this = this
    this.$form.on("submit", function () {
      if (!_this.$form.data("save-draft")) {
        // Validate agreement if present
        if ($("#artwork-agreement-input").length > 0 && !$("#artwork-agreement-input").is(":checked")) {
          $("#agree-warning").removeClass("hidden", function () {
            bt.scrollTo($(this))
          })
          return false
        }

        // Validate primary photo uploading
        const $uploader = $(".Attachments")
        const isFrameable = _this.checkFrameable()
        const $uploaderMessage = isFrameable ? $uploader.find(".primary-frame-error") : $uploader.find(".primary-error")

        if ($uploader.attr("data-primary-uploaded") == 0) {
          _this.handleUploadError($uploader, $uploaderMessage)
          return false
        } else {
          if (isFrameable) {
            if ($uploader.attr("data-preview-uploaded") == 0) {
              _this.handleUploadError($uploader, $uploaderMessage)
              return false
            }
          } else {
            $uploaderMessage.addClass("hidden")
          }
        }

        // Validate availability dates
        var $availability = $(".Availability")
        var availabilityState = $availability.attr("data-availability-state")

        // Dispatch from
        var $dispatchFromGroup = $("#new-artwork-dispatch-from")
        $dispatchFromGroup.removeClass("has-error")

        if (availabilityState == "dispatch_from") {
          if ($availability.attr("data-availability-dispatch") == undefined) {
            $dispatchFromGroup.addClass("has-error")
          }
        }

        // Available from
        var $availableFromGroup = $("#new-artwork-available-from")
        $availableFromGroup.removeClass("has-error")

        if (availabilityState == "available_from") {
          if ($availability.attr("data-availability-available") == undefined) {
            $availableFromGroup.addClass("has-error")
          }
        }

        _this.validateInputs()

        if ($(".has-error").length > 0) {
          bt.scrollTo($(".has-error"))
          return false
        } else {
          // We submit frame data even though the selection is disabled.
          var $frameless = $("#user_listing_listing_attributes_frame").get(0)
          $frameless.disabled = false
        }
      }
    })
  },

  toggleMaterials: function (option, $el, callback) {
    if (this.selectedMaterial === option) {
      $el.removeClass("hidden").addClass("required")
    } else {
      $el.removeClass("required").addClass("hidden")
      if (callback != undefined) {
        callback()
      }
    }
  },

  toggleSignaturesList: function (isPrintable) {
    var _this = this
    var $signatures = this.$form.find("#user_listing_listing_attributes_signature").find("option")
    var regularSigns = ["front", "back", "certificate"]
    var printableSigns = ["numbered_front", "numbered_back", "numbered_certificate"]

    if (isPrintable) {
      $signatures.each(function (index, el) {
        _this.toggleSignature(el, regularSigns)
      })
    } else {
      $signatures.each(function (index, el) {
        _this.toggleSignature(el, printableSigns)
      })
    }
  },

  toggleSignature: function (el, arr) {
    var $el = $(el)
    if (arr.includes($el.val())) {
      $el.attr("disabled", "disabled")
    } else {
      $el.attr("disabled", false)
    }
  },

  toggleSizes: function ($el) {
    var $framed = $(".new-artwork__section-container__framed")
    var $unframed = $(".new-artwork__section-container__unframed")
    var $weights = $el.closest(".new-artwork__section-container__field").find(".new-artwork__section-container__weight")
    var $weightClone = $($weights[0]).clone(true, true)
    var $framedInputs = $framed.find("input")

    $weights.remove()

    if ($el.find("option:selected").val() == "true") {
      $framed.find(".row").append($weightClone)
      $framed.removeClass("hidden")
      $framedInputs.addClass("not-zero greater-than-or-equals-to")
    } else {
      $unframed.find(".row").append($weightClone)
      $framed.addClass("hidden")
      $framed.find(".has-error").remove()
      $framedInputs.removeClass("not-zero greater-than-or-equals-to")
    }
  },

  calculateTotal: function () {
    const framed = $("#user_listing_listing_attributes_frame").val() == "true"
    const dimension_prefix = `#user-listing-${framed ? "frame-" : ""}`
    const width = $(`${dimension_prefix}width`).val()
    const height = $(`${dimension_prefix}height`).val()
    const depth = $(`${dimension_prefix}depth`).val()
    const weight = $("#user-listing-weight").val()
    const price = $("#listing-price").val()
    const shipmentType = $("input[type='radio'].shipment-type:checked").val()
    const rolled_depth_warning_ref = $("#rolled-depth-warning")
    !framed && depth < 1 && shipmentType == "cardboard"
      ? rolled_depth_warning_ref.removeClass("hidden").addClass("block")
      : rolled_depth_warning_ref.removeClass("block").addClass("hidden")

    // early return if price is not minimum
    if (price < this.minimumPrice) {
      return
    }

    if (width > 0 && height > 0 && depth > 0 && weight > 0 && price > 0) {
      var url = window.location.origin + "/my/artworks/shipping_calculation"

      var listingData = {
        width: width,
        height: height,
        depth: depth,
        weight: weight,
        price: price,
        shipment_type: shipmentType,
        frame: "false",
      }

      $.get(
        url,
        {
          listing: listingData,
          listing_id: $("[data-listing-id]").data("listingId"),
          artist_id: $("[data-artist-id]").data("artistId"),
        },
        function (data) {
          $("#artist-commission-display").text(data.commission)
          $("#artist-profit-display").text(data.profit)
          $("#shipping-price-display").text(data.shipping)
          $("#total-price-display").text(data.total)
        },
      )
    }
  },

  validateInputs: function () {
    var listingPriceInput = this.$form.find("#listing-price")
    var listingTitleInput = $("#listing-title-input")

    if (parseInt(listingPriceInput.val()) < userListingForm.minimumPrice) {
      listingPriceInput.parent(".form-group").addClass("has-error")
      listingPriceInput.parent(".form-group").find(".help-block").remove()
      listingPriceInput
        .parent(".form-group")
        .append("<span class='help-block'>minimum price is $" + userListingForm.minimumPrice + "</span>")
      listingPriceInput.closest(".collapse").collapse("show")
    } else {
      listingPriceInput.parent(".form-group").removeClass("has-error")
      listingPriceInput.parent(".form-group").find(".help-block").remove()
      listingPriceInput.parent(".form-group").find(".help-block").remove()
    }

    this.$form.find("input.required, select.required").each(function (index) {
      var $parent = $(this).parent(".form-group")
      if ($(this).val().length == 0) {
        $parent.addClass("has-error")
        $parent.find(".help-block").remove()
        $parent.append("<span class='help-block'>is required.</span>")
        $(this).closest(".collapse").collapse("show")
      } else {
        $parent.removeClass("has-error")
        $parent.find(".help-block").remove()
        $parent.find(".help-block").remove()
      }
    })

    if (listingTitleInput.val().indexOf("- Duplicate") > 0 || listingTitleInput.val().indexOf("- Copy") > 0) {
      listingTitleInput.parent(".form-group").addClass("has-error")
      listingTitleInput.parent(".form-group").find(".help-block").remove()
      listingTitleInput
        .parent(".form-group")
        .append("<span class='help-block'>Please update the title after duplicating the artwork</span>")
      listingTitleInput.closest(".collapse").collapse("show")
    }

    this.$form.find("input.not-zero").each(function (index) {
      var $parent = $(this).parent(".form-group")
      if ($(this).val() == "0" || $(this).val().length == 0) {
        $parent.addClass("has-error")
        $parent.find(".help-block").remove()
        $parent.append("<span class='help-block'>must not be zero</span>")
        $(this).closest(".collapse").collapse("show")
      } else {
        $parent.removeClass("has-error")
        $parent.find(".help-block").remove()
      }
    })

    const framed = $("#user_listing_listing_attributes_frame").val() == "true"
    const shipmentType = $("input[type='radio'].shipment-type:checked").val()

    if (framed && shipmentType == "rolled") {
      $("#shipping")
        .find(".new-artwork__section-container")
        .append("<div class='alert alert-danger has-error'>Framed artworks can't be rolled!</div>")
      $("#shipping").collapse("show")
    } else $("#shipping").find(".has-error").remove()

    this.$form.find("input.greater-than-or-equals-to").each(function () {
      var $parent = $(this).parent(".form-group")

      if (parseFloat($(this).val()) < parseFloat($(`#${this.dataset.greaterThanOrEqualsToTarget}`).val())) {
        $parent.addClass("has-error")
        $parent.find(".help-block").remove()
        $parent.append(
          `<span class='help-block'>must not be lower than ${this.dataset.greaterThanOrEqualsToTargetLabel}</span>`,
        )
        $(this).closest(".collapse").collapse("show")
      } else {
        $parent.removeClass("has-error")
        $parent.find(".help-block").remove()
      }
    })
  },

  handleCleanTitleValidation: function () {
    $("#listing-title-input").on("keyup", (e) => {
      this.validateCleanTitle()
    })
    $("#clean-title-use-suggested").on("click", (e) => {
      var suggestedTitle = this.$form.find("#clean-title-suggested").text()
      this.$form.find("#listing-title-input").val(suggestedTitle)
      this.validateCleanTitle()
    });
  },

  validateCleanTitle: function () {
    const title = this.$form.find("#listing-title-input").val()

    if(this.$form.cleanTitleCategories === undefined) {
      const styleData = document.getElementById("style-data")
      this.$form.cleanTitleCategories = JSON.parse(styleData.getAttribute("data-options")).map(option => option.title)
    }

    const dimensionsRegex = /\b\d+(\.\d+)?\s*(cm|mm|in)?\s*x\s*\d+(\.\d+)?\s*(cm|mm|in)\b/ig
    const saleTermsRegex = /\b(SALE|DISCOUNT|OFFER|FREE|PROMO|BARGAIN)\b/ig
    const allUppercaseRegex = /^[A-Z\s]+$/
    const specialCharactersRegex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g
    const categoriesRegex = new RegExp(`\\b(${this.$form.cleanTitleCategories.join("|")})\\b`, "ig")

    const dimensionsValid = !dimensionsRegex.test(title)
    const saleTermsValid = !saleTermsRegex.test(title)
    const allUppercaseValid = !allUppercaseRegex.test(title)
    const specialCharactersValid = !specialCharactersRegex.test(title)
    const categoriesValid = !categoriesRegex.test(title)

    this.$form.find("#clean-title-dimensions-rule").toggleClass("valid", dimensionsValid)
    this.$form.find("#clean-title-sale-terms-rule").toggleClass("valid", saleTermsValid)
    this.$form.find("#clean-title-all-uppercase-rule").toggleClass("valid", allUppercaseValid)
    this.$form.find("#clean-title-special-characters-rule").toggleClass("valid", specialCharactersValid)
    this.$form.find("#clean-title-categories-rule").toggleClass("valid", categoriesValid)

    if(dimensionsValid && saleTermsValid && allUppercaseValid && specialCharactersValid && categoriesValid) {
      this.$form.find("#clean-title-suggested-container").addClass("hidden")
    } else {
      const cleanTitle = title
        .replace(dimensionsRegex, "")
        .replace(saleTermsRegex, "")
        .replace(allUppercaseRegex, "")
        .replace(specialCharactersRegex, "")
        .replace(categoriesRegex, "")
        .replace(/\s+/g, ' ')
      if(cleanTitle.replace(/\s/g, '').length < 3) {
        this.$form.find("#clean-title-suggested-container").addClass("hidden")
      } else {
        this.$form.find("#clean-title-suggested").text(cleanTitle)
        this.$form.find("#clean-title-suggested-container").removeClass("hidden")
      }
    }
  }
}
