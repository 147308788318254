import Vue, { defineAsyncComponent } from "vue"

let NewArtworkPage = {
  initialize() {
    let attachmentsEl = document.getElementById("vue-new-artwork-attachments")
    if (attachmentsEl != null) {
      const Attachments = defineAsyncComponent(() => import("@/pages/NewArtworkPage/Attachments.vue"))
      this.app = new Vue({
        el: attachmentsEl,
        render: (h) =>
          h(Attachments, {
            props: {
              listingId: attachmentsEl.getAttribute("data-listing-id"),
              attachments: JSON.parse(attachmentsEl.getAttribute("data-attachments")),
              inSituUrl: attachmentsEl.getAttribute("data-in-situ-url"),
              wallsCount: parseInt(attachmentsEl.getAttribute("data-insitu-walls-count")),
            },
          }),
      })
    }

    let availabilityEl = document.getElementById("vue-new-artwork-availability")
    if (availabilityEl != null) {
      let attributes = JSON.parse(availabilityEl.getAttribute("data-attributes")).availability
      const Availability = defineAsyncComponent(() => import("@/pages/NewArtworkPage/Availability.vue"))

      this.app = new Vue({
        el: availabilityEl,
        render: (h) =>
          h(Availability, {
            props: {
              defaults: attributes.defaults,
              soldReasons: attributes.sold_reasons,
              dispatchFrom: attributes.dispatch_from,
              availableFrom: attributes.available_from,
            },
          }),
      })
    }

    let colorPickerEl = document.getElementById("vue-new-artwork-color-picker")
    if (colorPickerEl != null) {
      const ColorPicker = defineAsyncComponent(() => import("@/pages/NewArtworkPage/ColorPicker.vue"))
      let colors = colorPickerEl.getAttribute("data-listing-colors")
      this.app = new Vue({
        el: colorPickerEl,
        render: (h) =>
          h(ColorPicker, {
            props: {
              primaryColors: JSON.parse(colorPickerEl.getAttribute("data-primary-colors")),
              colorsLimit: parseInt(colorPickerEl.dataset.colorsLimit),
              listingColors: colors != "" ? colors.split(" ") : undefined,
            },
          }),
      })
    }

    let mediumEl = document.getElementById("vue-new-artwork-mediums")
    if (mediumEl != null) {
      const SingleSelectDropdown = defineAsyncComponent(() => import("@/pages/NewArtworkPage/SingleSelectDropdown.vue"))
      this.app = new Vue({
        el: mediumEl,
        render: (h) =>
          h(SingleSelectDropdown, {
            props: {
              label: "Medium",
              id: "user_listing_listing_attributes_medium_id",
              name: "user_listing[listing_attributes][medium_id]",
              options: JSON.parse(mediumEl.getAttribute("data-options")),
              existing: JSON.parse(mediumEl.getAttribute("data-existing")) || "",
              broadcastEvents: true,
            },
          }),
      })
    }

    let materialsEl = document.getElementById("vue-new-artwork-materials")
    if (materialsEl != null) {
      const SingleSelectDropdown = defineAsyncComponent(() => import("@/pages/NewArtworkPage/SingleSelectDropdown.vue"))
      this.app = new Vue({
        el: materialsEl,
        render: (h) =>
          h(SingleSelectDropdown, {
            props: {
              label: "Surface/Material",
              id: "user_listing_listing_attributes_material",
              name: "user_listing[listing_attributes][material]",
              options: JSON.parse(materialsEl.getAttribute("data-options")),
              existing: materialsEl.getAttribute("data-existing") || "",
              broadcastEvents: true,
            },
          }),
      })
    }

    let collectionEl = document.getElementById("vue-new-artwork-collections")
    if (collectionEl != null) {
      const MultiSelectDropdown = defineAsyncComponent(() => import("@/pages/NewArtworkPage/MultiSelectDropdown.vue"))
      this.app = new Vue({
        el: collectionEl,
        render: (h) =>
          h(MultiSelectDropdown, {
            props: {
              label: "Subject",
              id: "listing-subject-collection",
              name: "user_listing[listing_attributes][collection_ids][]",
              options: JSON.parse(collectionEl.getAttribute("data-options")),
              existing: JSON.parse(collectionEl.getAttribute("data-existing")),
            },
          }),
      })
    }

    let artStyleEl = document.getElementById("vue-new-artwork-art-styles")
    if (artStyleEl != null) {
      const MultiSelectDropdown = defineAsyncComponent(() => import("@/pages/NewArtworkPage/MultiSelectDropdown.vue"))
      this.app = new Vue({
        el: artStyleEl,
        render: (h) =>
          h(MultiSelectDropdown, {
            props: {
              label: "Style",
              id: "listing-style-collection",
              name: "user_listing[listing_attributes][art_style_ids][]",
              options: JSON.parse(artStyleEl.getAttribute("data-options")),
              existing: JSON.parse(artStyleEl.getAttribute("data-existing")),
            },
          }),
      })
    }
  },
}

document.addEventListener("turbo:load", () => {
  NewArtworkPage.initialize()
})

document.addEventListener("turbo:render", () => {
  NewArtworkPage.initialize()
})
